import AjaxIntro from './AjaxIntro'

export default [
  {
    label: '!',
    component: AjaxIntro
  }
]
/*
что здесь?
(скорее всего без браузера)
get, post, put, delete

• категории доходов/расходов, выборка расходов/доходов
• получение токена для авторизации по логину и паролю
• отправка запросов с токеном в headers
• добавление доходов/расходов
• изменение доходов/расходов
• удаление доходов/расходов
• добавление категорий
• изменение категории
• удаление категорий

• филиалы, группы, состав
• добавить в группу
• удалить из группы

вводим дату, нужно определить, выходной это день или нет

*/
