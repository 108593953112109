<template>
  <h2>Сетевые запросы</h2>
  <p><a href="https://learn.javascript.ru/fetch" class="link" target="_blank">Здесь читаем про fetch</a></p>
  <p><a href="https://axios-http.com/ru/docs/intro" class="link" target="_blank">Здесь читаем про axios</a></p>
</template>

<script>
export default {
  props: ['task']
}
</script>
